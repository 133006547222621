<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="elevation-1">
            Trial Room Details
            <v-spacer></v-spacer>
            <v-flex
              xs12
              sm6
              md4
              v-if="
                data.shippingStatus == 'Pending' ||
                data.shippingStatus == 'Confirmed' ||
                data.shippingStatus == 'Packed' ||
                data.shippingStatus == 'Shipped'
              "
            >
              <v-select
                v-model="data.shippingStatus"
                :items="verifyStatus"
                item-text="name"
                item-value="value"
                item-color="#8d8d8d"
                color="#8d8d8d"
                outlined
                dense
                label="Change Status"
                @change="askStatus(data._id, data.shippingStatus)"
              >
              </v-select>
            </v-flex>
          </v-card-title>
        </v-card>
        <div>
          <v-card>
            <v-layout py-2 justify-start v-if="data">
              <v-flex md3 sm3 xs3 lg3 xl3 v-if="data.product">
                <v-img
                  height="300px"
                  width="300px"
                  contain
                  :src="baseURL + data.product.photos[0]"
                >
                </v-img>
              </v-flex>
              <v-flex md9 xl9 sm9 xs9 lg9>
                <h2 style="padding-top: 15px" v-if="data.product">
                  {{ data.product.productname }}
                </h2>
                <br />
                <span style="font-size: 18px; color: black"
                  >Size : {{ data.size }}</span
                >
                &nbsp;
                <span style="font-size: 18px; color: black" v-if="data.product"
                  >Rs : {{ data.product.price }}</span
                > <br> <br>
                <h4>Occasion</h4>
                <span>{{ data.occation }}</span>
                <v-layout wrap>
                  <v-flex xs12 py-5>
                    <h3>Store Details</h3>
                  </v-flex>
                  <v-flex xs12 sm12 v-if="data.store">
                    <h4>{{ data.store.organization }}</h4>
                    <v-btn
                      x-small
                      outlined
                      color="red"
                      @click="sellerinfo(data.store._id)"
                    >
                      <span>View Seller Details</span>
                    </v-btn>
                  </v-flex>
                  <!-- <v-flex xs12 sm5 md1 text-left>
                                      
                                    </v-flex> -->
                  <v-flex xs12 v-if="data.store">
                    <span style="font-size: 18px; color: black"
                      >Owner Name : {{ data.store.firstname }}
                      {{ data.store.lastname }}</span
                    >
                    &nbsp;
                    <span style="font-size: 18px; color: black"
                      >Phone : {{ data.store.phone }}</span
                    >
                    &nbsp;
                    <span style="font-size: 18px; color: black"
                      >Email : {{ data.store.email }}</span
                    ><br />
                    <span style="font-size: 18px; color: black"
                      >Address : {{ data.store.streetAddress }}</span
                    >
                    &nbsp;
                    <span style="font-size: 18px; color: black">
                      {{ data.store.pincode }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap pt-5 px-8>
              <v-flex xs12 pb-4>
                <h3>User Details</h3>
              </v-flex>
              <v-flex xs12 v-if="data.user">
                <h4>{{ data.user.firstname }} {{ data.user.lastname }}</h4>
              </v-flex>
              <v-flex xs12 v-if="data.user">
                <span style="font-size: 18px; color: black"
                  >Phone : {{ data.user.phone }}</span
                >
                &nbsp;
                <span style="font-size: 18px; color: black"
                  >Email : {{ data.user.email }}</span
                ><br />
                <span style="font-size: 18px; color: black"
                  >Address :
                  {{ data.user.deliveryaddress[0].streetAddress }}</span
                >
                &nbsp;
                <span style="font-size: 18px; color: black">
                  {{ data.user.pincode }}</span
                >
              </v-flex>
            </v-layout>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="updateDialog" max-width="600px">
      <v-card>
        <v-card-title style="font-size: 16px"
          >Are you sure you want to change Status?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="updateDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="updateStatus(dialogId, dialogStatus)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      msg: null,
      ServerError: false,
      showsnackbar: false,
      data: [],
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      verifyStatus: ["Pending", "Confirmed", "Packed", "Shipped"],
      updateDialog: false,
      dialogId: null,
      dialogStatus: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/trial/admin/product/view",
        data: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.data = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    askStatus(id, status) {
      this.updateDialog = true;
      this.dialogId = id;
      this.dialogStatus = status;
    },
    updateStatus(id, status) {
      this.updateDialog = false;
      axios({
        url: "/trial/admin/status/update",
        method: "POST",
        data: { id: id, status: status },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            this.dialogId = null;
            this.dialogStatus = null;
            this.msg = "Status updated successfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    sellerinfo(sid) {
      this.$router.push({ name: "sellerDetails", params: { sellerid: sid } });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}

.item_value {
  font-size: 13px;
}
</style>